<!--  -->
<template>
  <div>
    <div class="bg">
      <div class="logo"></div>
    </div>
    <div class="info">
      <div class="tips">开启轻松赚钱之旅</div>
      <div class="downloadBtn">
        <div class="btn">立即下载</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.bg {
  height: 981px;
  background-image: url(https://t.yhaocang.com/upload/sf_static/img/withdrawal/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  .logo {
    position: absolute;
    width: 152px;
    height: 235px;
    top: 149px;
    background-image: url(https://t.yhaocang.com/upload/sf_static/img/withdrawal/bg-logo.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .tips {
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1f211c;
    line-height: 37px;
    text-align: center;
    margin-top: 92px;
  }
  .downloadBtn {
    margin-left: 57px;
    margin-right: 57px;
    margin-top: 56px;
    .btn {
      width: 100%;
      height: 90px;
      background: #242a1d;
      border-radius: 45px;
      text-align: center;
      line-height: 90px;
      color: #ffffff;
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }
}
</style>
